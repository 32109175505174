
import Vue from "vue";

import http from "@/http";

import FileDownload from "js-file-download";

import { CancelResponse, Cancel, Store, Process, Item } from "@/interfaces";

import MyCard from "@/components/cancel/MyCard.vue";
import MyPager from "@/components/MyPager.vue";

import { mdiMagnify, mdiCloudDownload } from "@mdi/js";

export interface Query {
  keyword: string;
  page: number;
  store_id: number;
  process_id: number;
  quote: boolean;
  insurance: boolean;
  state: string;
  begin: string;
  end: string;
  sort: number;
}

export interface DataType {
  cancels: Cancel[];
  query: Query;
  page: number;
  hasPrev: boolean;
  hasNext: boolean;
  menu: boolean;
  dates: string[];
}

export default Vue.extend({
  components: {
    MyCard,
    MyPager
  },
  data(): DataType {
    return {
      cancels: [],
      query: {
        keyword: "",
        page: 0,
        store_id: 0,
        process_id: 0,
        quote: false,
        insurance: false,
        state: "",
        begin: "",
        end: "",
        sort: 1
      },
      page: 1,
      hasPrev: false,
      hasNext: false,
      menu: false,
      dates: ["", ""]
    };
  },
  computed: {
    icon() {
      return {
        mdiMagnify,
        mdiCloudDownload
      };
    },
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "解約",
          disabled: true,
          to: "/cancels"
        }
      ];
    },
    items(): Item[] {
      return [
        { value: 1, text: "受付日" },
        { value: 2, text: "解約日" }
      ];
    },
    stores(): Item[] {
      return this.$store.state.stores
        .map((store: Store) => {
          return {
            text: store.name,
            value: store.id
          };
        })
        .concat([
          {
            text: "--",
            value: 0
          }
        ]);
    },
    processes(): Item[] {
      return this.$store.state.processes
        .map((store: Process) => {
          return {
            text: store.name,
            value: store.id
          };
        })
        .concat([
          {
            text: "--",
            value: 0
          }
        ]);
    },
    states(): Item[] {
      return [
        { value: "no_reservation", text: "予約なし" },
        { value: "fixed", text: "確定" },
        { value: "unfixed", text: "未確定" },
        { value: "", text: "--" }
      ];
    },
    href(): string {
      const params = {
        ...this.query,
        quote: this.query.quote ? 1 : 0,
        insurance: this.query.insurance ? 1 : 0,
        token: this.$store.state.token
      };

      const query = Object.entries(params)
        .map(e => `${e[0]}=${e[1]}`)
        .join("&");

      return `${process.env.VUE_APP_API_URL}/download?${query}`;
    }
  },
  watch: {
    async $route() {
      await this.fetchData();
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async submit() {
      const [begin, end] = this.dates;
      await this.$router
        .replace({
          path: "/cancels",
          query: {
            keyword: this.query.keyword,
            page: this.query.page.toString(),
            store_id: this.query.store_id.toString(),
            process_id: this.query.process_id.toString(),
            quote: this.query.quote.toString(),
            insurance: this.query.insurance.toString(),
            state: this.query.state,
            sort: this.query.sort.toString(),
            begin,
            end
          }
        })
        .catch(() => {});
    },

    async next() {
      const page = +this.query.page;

      this.query.page = page + 1;

      await this.submit();
    },

    async prev() {
      const page = +this.query.page;

      this.query.page = page - 1;

      await this.submit();
    },
    handleQuery() {
      let keyword = "";
      let page = 1;
      let store_id = 0;
      let process_id = 0;
      let quote = false;
      let insurance = false;
      let state = "";
      let begin = "";
      let end = "";
      let sort = 1;

      if (typeof this.$route.query.keyword === "string") {
        keyword = this.$route.query.keyword;
      }

      if (typeof this.$route.query.page === "string") {
        page = +this.$route.query.page;
      }

      if (typeof this.$route.query.store_id === "string") {
        store_id = +this.$route.query.store_id;
      }

      if (typeof this.$route.query.process_id === "string") {
        process_id = +this.$route.query.process_id;
      }

      if (typeof this.$route.query.state === "string") {
        state = this.$route.query.state;
      }

      if (typeof this.$route.query.begin === "string") {
        begin = this.$route.query.begin;
      }

      if (typeof this.$route.query.end === "string") {
        end = this.$route.query.end;
      }

      if (typeof this.$route.query.sort === "string") {
        sort = +this.$route.query.sort;
      }

      if (typeof this.$route.query.quote === "string") {
        quote = this.$route.query.quote === "true";
      }

      if (typeof this.$route.query.insurance === "string") {
        insurance = this.$route.query.insurance === "true";
      }

      this.query = {
        keyword,
        page,
        store_id,
        process_id,
        quote,
        insurance,
        state,
        begin,
        end,
        sort
      };

      this.dates = [begin, end];
    },
    async fetchData() {
      this.handleQuery();

      const params = {
        ...this.query,
        quote: this.query.quote ? 1 : 0,
        insurance: this.query.insurance ? 1 : 0
      };

      const { data } = await http.get<CancelResponse>("cancels", {
        params
      });

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;

      this.cancels = data.data;
    },
    async click() {
      const [begin, end] = this.dates;

      const params = {
        ...this.query,
        quote: this.query.quote ? 1 : 0,
        insurance: this.query.insurance ? 1 : 0,
        begin,
        end
      };

      const { data } = await http.get("download/cancels", {
        params,
        responseType: "blob"
      });

      FileDownload(data, "cancels.xlsx");
    }
  }
});
