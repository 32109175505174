
import Vue, { PropType } from "vue";

import { Cancel } from "@/interfaces";

import MyOverview from "@/components/cancel/MyOverview.vue";

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  computed: {
    labels() {
      const label = [];
      if (this.cancel.is_parking) {
        label.push("駐車場");
      }
      if (!this.cancel.presence_required) {
        label.push("立ち会いなし");
      }

      return label;
    }
  }
});
