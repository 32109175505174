
import Vue, { PropType } from "vue";

import http from "@/http";

import MyFormattedDate from "@/components/MyFormattedDate.vue";
import MyCustomer from "@/components/cancel/MyCustomer.vue";

import { Cancel, Snackbar, Member, Customer } from "@/interfaces";

export default Vue.extend({
  components: {
    MyFormattedDate,
    MyCustomer
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  data() {
    return {
      insurance: false
    };
  },
  computed: {
    hasQuote(): boolean {
      return this.cancel.quote === 1;
    },
    customers(): Customer[] {
      let customers: Customer[] = [];

      if (this.cancel.user && this.cancel.user.customer) {
        return [this.cancel.user.customer]
      }

      if (this.cancel.cancelers.length > 0) {
        customers = [...this.cancel.cancelers]
      }

      if (this.cancel.members.length > 0) {
        customers = [...customers, ...this.cancel.members]
      }

      return customers
    },
    company(): string {
      if (this.cancel.company != null) {
        return this.cancel.company.name;
      } else {
        return "--";
      }
    },
    name(): string {
      const { customer, member } = this.cancel.user;
      if (customer) {
        return customer.name;
      }

      if (member) {
        return member.name;
      }

      return "--";
    },
    kana(): string {
      const { customer, member } = this.cancel.user;
      if (customer) {
        return customer.kana;
      }

      if (member) {
        return member.kana;
      }

      return "--";
    },
    tel(): string {
      const { customer, member } = this.cancel.user;
      if (customer) {
        return customer.tel;
      }

      return "--";
    }
  },
  mounted() {
    this.insurance = this.cancel.insurance_required;
  },
  methods: {
    async change() {
      const { id } = this.cancel;

      const url = `cancels/${id}`;

      const insurance_required = this.insurance;

      const { data } = await http.put<Cancel>(url, {
        insurance_required
      });

      const snackbar: Snackbar = {
        show: true,
        content: "変更しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);
    }
  }
});
